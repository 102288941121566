// Font Awesome
// Font icon set

import { library, dom } from "@fortawesome/fontawesome-svg-core";
import {
  faSearch,
  faTimes,
  faAngleRight,
  faPhoneAlt,
  faAngleLeft,
  faMapMarkerAlt,
  faEnvelope,
  faPrint,
  faPlus,
  faMinus,
  faAngleDown,
  faCalendarAlt,
  faClock,
  faUserAlt,
  faBriefcaseMedical,
  faClipboardList
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faPinterestP,
  faInstagram,
  faTwitter
} from "@fortawesome/free-brands-svg-icons";

$(document).ready(() => {
  library.add(
    faTwitter,
    faClipboardList,
    faUserAlt,
    faBriefcaseMedical,
    faSearch,
    faTimes,
    faAngleRight,
    faPhoneAlt,
    faAngleLeft,
    faMapMarkerAlt,
    faEnvelope,
    faFacebookF,
    faPinterestP,
    faInstagram,
    faPrint,
    faPlus,
    faMinus,
    faAngleDown,
    faCalendarAlt,
    faClock
  );

  dom.i2svg();
});
