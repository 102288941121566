$(document).ready(function() {
  $("#homeSlider").slick({
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    appendDots: $("#homeDots"),
    autoplay: true,
    autoplaySpeed: 3500
  });
});
